import { Slot } from 'BaxterScript/types/Slot';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import * as Html from 'BaxterScript/helper/browser/Html';

type ProviderSpecificTracker = () => Promise<void>;

export const onClickHandler = (
  provider: Providers.AD_MANAGER_STATIC | Providers.AD_MANAGER,
  source: string,
  slot: Slot,
  parameters: Record<string, unknown> = {},
  providerSpecificTracker?: ProviderSpecificTracker,
  destinationUrl?: string
) => {
  let preventDoubleClick = false;
  let preventMultipleClickTrackingRequests = false;
  return async () => {
    try {
      console.info(`[SLOTS][${provider.toUpperCase()}][ONCLICKHANDLER]`, source, slot, destinationUrl, parameters);
      if (!preventDoubleClick) {
        preventDoubleClick = true;
        if (!preventMultipleClickTrackingRequests) {
          preventMultipleClickTrackingRequests = true;
          if (providerSpecificTracker) {
            await providerSpecificTracker();
          }
          slot[provider].callbacks.slotClickedCallback(source, slot, parameters);
        }
        if (destinationUrl) {
          window.open(destinationUrl, '_blank');
        }
        preventDoubleClick = false;
      }
    } catch (e) {
      console.error(`[SLOTS][${provider.toUpperCase()}][ONCLICKHANDLER]`, e);
      newRelicMetrics.reportError(NewRelicError.ADMANAGER_CLICK_HANDLER_ERROR, { message: (e as Error).message });
    }
  };
};

export const setBannerClickHandler = (bannerId, onClick: (event) => Promise<void>) => {
  const image = Html.getElementById(bannerId);
  image?.addEventListener('click', onClick);
};

export const setHtmlClickHandler = (slot: Slot, onClick: (event) => Promise<void>) => {
  const elements = slot.innerHtmlElement.getElementsByClassName('baxter-destination-click-handler');
  if (elements) {
    for (const element of elements) {
      element.addEventListener('click', onClick);
    }
  }
};
